import React from 'react'
import {Link} from 'react-scroll'
function Welcome2() {
    return (
        <section>
            <div className="block color-scheme-1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-sub-title center">
                                <article className="section-title-body white">
                                    <h1 className="head-title">Building Your <span>Property Sourcing Business...</span> <br/>Starts Here and Now !</h1>
                                        <p className="head-text" style={{textAlign: 'justify'}}>
                                            We are the UK's #1 Fastest Growing Property Network and the market's best kept secret. Our network exists to take any ordinary person from fresh beginner with £0 to a seasoned property investor making constant deals and taking huge wins home. <br/><br/>If you want to learn how to source properties - we are the best in Scotland. If you need access to an exceptionally active and collaborative network - we are one of the highest performing property networks in the UK. There are deals being done every week, and our members are winning every day - check out our Testimonials down below.
                                        </p>
                                        <Link to='portfolio' className="btn-default white btn-move" smooth={false} duration={800}>TESTIMONIALS</Link>&nbsp;
                                        <Link to="#" onClick={()=>{
                                            alert("IMPORTANT! Please ensure to enter your mobile phone number in the right format (such as +447490001234) in the checkout page to get instant access to the WhatsApp Group.");
                                            window.open('https://buy.stripe.com/aEUcQyaYw0FOdUc6or', '_blank');
                                        }} className="btn-default active btn-move t1" smooth={false} duration={800}>JOIN NOW FOR £69</Link>
                                </article>
                            </div>   
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Welcome2
