import React from 'react'

function Map() {
    return (
        <>
         <div className="google-map">

            <iframe  title="My Address" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=14%20Newton%20Place,%20Glasgow+(PSN)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" ></iframe>

        </div>   
        </>
    )
}

export default  Map
