import React from 'react';
import ReactDOM from 'react-dom';
import Template from './pages/Home3';

const showComingSoon = false;

ReactDOM.render(
    <>
      {showComingSoon === true || window.location.toString().includes("login") ? window.location.replace('https://sourcing-network-scotland.web.app/session/signin') : <Template/>}
    </>,
  document.getElementById('root')
);

