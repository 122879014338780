import React from 'react'
import {Link} from 'react-scroll'

function Featured() {
    return (
        <>
            <div className="color-scheme-1 block">
                <div className="container">
                    <div className="section-sub-title center">
                        <article className="section-title-body white">
                            <h1 className="head-title">The PSN Course</h1>
                            <p className="head-text" style={{textAlign: 'justify'}}>
                                Value-packed teachings broken down into bite-sized modules. The highest-performing industry experts hosting live events. Everything you need to know on setting up and scaling your property business. All essentials and foundations, so that you never have to backtrack. A host of live expert deal-done case studies, for you to analyse and implement.
                            </p>
                        </article>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 order-md-0 order-lg-1">
                            <div className="moon-block">
                                <span className="moon"></span>
                                <img alt="" className="img-fluid" src="img/preview/feature.jpg"/>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 order-md-1 order-lg-0">
                            <div className="feature">
                                    <article className="widget-block right">
                                        <h4 className="widget-title">12+ Hours of Content</h4>
                                        <p>
                                            Value-packed teachings broken down into bite-sized modules
                                        </p>
                                    </article>
                                    <article className="widget-block right">
                                        <h4 className="widget-title">Expert Speakers</h4>
                                        <p>
                                            The highest-performing industry experts hosting live events
                                        </p>
                                    </article>
                                    <article className="widget-block right d-lg-none d-xl-block">
                                        <h4 className="widget-title">How to</h4>
                                        <p>
                                            Everything you need to know on setting up and scaling your property business
                                        </p>
                                    </article>
                            </div>
                        </div>
                        <div className="col-lg-3  col-md-6  order-md-2  order-lg-3">
                        <div className="feature">
                                <article className="widget-block">
                                    <h4 className="widget-title">Business fundamentals</h4>
                                    <p>
                                        All essentials and foundations, so that you never have to backtrack
                                    </p>
                                </article>
                                <article className="widget-block">
                                    <h4 className="widget-title">Case Studies</h4>
                                    <p>
                                        A host of live expert deal-done case studies, for you to analyse and implement
                                    </p>
                                </article>
                            </div>
                        </div>
                    </div>
                    <center><Link to="#" onClick={()=>window.open('https://buy.stripe.com/9AQ17Q6Ig3S08zS7sE', '_blank')} className="btn-default active btn-move t1" smooth={false} duration={1200} style={{color: 'white', backgroundColor: '#b46e3c', width: 300, textAlign: 'center'}}>GET THE COURSE NOW</Link></center>
                    <center><p style={{marginTop: -45}}><u><a href="https://buy.stripe.com/9AQ17Q6Ig3S08zS7sE">→Finance available</a></u></p></center>
                    <center><div style={{marginTop: -30}}><span><img src="/img/klarna.png" height="50"/></span></div></center>
                </div>
            </div> 
        </>
    )
}

export default Featured
