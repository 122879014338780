import React, {useState} from 'react'
import {Link} from 'react-scroll'
import HyperModal from 'react-hyper-modal';

function About3() {
    const [isOpen, setIsOpen] = useState(false);
    const membershipIncludes =[
        {
            _id:'001',
            classIcon:'ion-android-checkmark-circle',
            title:'Access to thousands (15,000+) investors ready to buy your deals',
            text:'Learn how to source off market and on market property deals and make between £3,000-£10,000 per deal.'
        },
        {
            _id:'002',
            classIcon:'ion-android-checkmark-circle',
            title:'Private Members Area',
            text:'Access to a private members area with recordings of ALL calls, templates & training videos.'
        },
        {
            _id:'003',
            classIcon:'ion-android-checkmark-circle',
            title:'WhatsApp & Facebook Groups',
            text:'Access to a private support WhatsApp & Facebook group to ask questions and get support.'
        },
        {
            _id:'004',
            classIcon:'ion-android-checkmark-circle',
            title:'JV Opportunities',
            text:'Opportunity to joint venture or source us deals direct and receive a full sourcing fee.'
        },
    ]
    return (
        <section  id="about">
            <div className="bg-dark"></div>    
            <div className="section-block-title">
                <div className="section-title">   
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-sm-10">
                                <article className="section-title-body">
                                    <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">About</h1>
                                    <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                    <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s" style={{textAlign: 'justify'}}>
                                        PSN Co-Founders David Smart & Matt Morrison, have been involved in hundreds of high-ticket property deals in Scotland since 2020. Within their portfolios, £18,500 flats and large-scale commercial to residential conversions were not scarce to come by. <br/><br/>Now, in 2024 - armed with hard-earned knowledge and acquired industry expertise, David & Matt wish to revolutionise the property industry, as well as the paths of those who walk the same road they do. Success doesn't come faster in property sourcing than with Property Sourcing Network.
                                    </p>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="color-scheme-blur">
                <div className="container">
                    <div className="row gx-5 align-items-center">
                        
                        <div className="col-md-6 col-sm-12">
                            <div className="relative">
                                <div className="h100  d-md-none"></div>
                                <div className="img-rotate wow fadeInLeftBig animated" data-wow-duration="1.5s">
                                    <img className="img-fluid" src="img/preview/desktop.png" alt="" onClick={()=>setIsOpen(true)}/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-sm-12">
                            <div className="block pad-l-30">
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 className="widget-title">The best Property Sourcing & training platform in the UK</h4>
                                <p>Property Sourcing Network, Scotland's highest-performing property network, attributes its success to exceptional expertise and a vibrant community, growing to over 100 members with minimal marketing, largely thanks to the industry reputation of co-founders David and Matt. This collaborative community ranges from beginners to seasoned sourcers and investors, equipped with valuable knowledge from property experts within the network. Rapidly expanding and climbing to the top of the UK market, the network promises significant learning and winning opportunities, inviting newcomers to join before its burgeoning popularity makes it mainstream.</p>
                                <h4 className="widget-title">Membership Includes</h4>
                            </div>
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                            <div className="clearfix"></div>
                            {membershipIncludes.map((service)=>(
                            <div key={service._id}>
                                <div className="block-feature">
                                    <i className={service.classIcon}></i>
                                    <a href="/">{service.title}</a>
                                    <p>
                                    {service.text}
                                    </p>
                                </div>
                            </div>
                            ))}
                            <Link to="#" onClick={()=>{
                                alert("IMPORTANT! Please ensure to enter your mobile phone number in the right format (such as +447490001234) in the checkout page to get instant access to the WhatsApp Group.");
                                window.open('https://buy.stripe.com/aEUcQyaYw0FOdUc6or', '_blank');
                            }} className="btn-default active btn-move t1" smooth={false} duration={1200} style={{color: 'white', backgroundColor: '#b46e3c'}}>JOIN NOW FOR £69</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HyperModal
                isOpen={isOpen}
                requestClose={()=>setIsOpen(false)}
                backgroundColor="#000"
            >
            
                <iframe style={{backgroundColor: "#000000"}} src="https://player.vimeo.com/video/933627986?h=0524fcf71c&color=b46e3c&title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </HyperModal>
        </section>
    )
}

export default About3
