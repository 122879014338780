import React from 'react'
import {FacebookShareButton,
    PinterestShareButton,
    TelegramShareButton,
    LinkedinShareButton,
    TwitterShareButton, InstapaperShareButton, InstapaperIcon} from "react-share";
import {FacebookIcon,
    PinterestIcon,
    TelegramIcon,
    LinkedinIcon,
    TwitterIcon} from "react-share";
function Team() {
  
    return (
        <>
        <section>
            <div className="block color-scheme-2">
                <div className="container">
                    <div className="section-sub-title center">
                        <article className="section-title-body white">
                            <h1 className="head-title">Meet Our Team</h1>
                        </article>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team1.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Co Founder & Director</p>
                                        <h4>David Smart</h4>
                                        
                                    </div>

                                </figure>
                                <div className="left-icon">
                                    <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.facebook.com/david.smart.7393/" 
                                            quote= "David Smart" hashtag="#davismartproperty">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url="https://uk.linkedin.com/in/david-smart-" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team2.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Co Founder & Director</p>
                                        <h4>Matt Morrison</h4>
                                        
                                    </div>

                                </figure>
                                <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.facebook.com/people/Matt-Morrison-Property-Group/100090011216590/" 
                                            quote= "Matt Morrison" hashtag="#mattmorrison">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url="https://uk.linkedin.com/company/matt-morrison-property-group" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div>
                        {/*<div className="col-lg-4 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team3.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Head of Marketing</p>
                                        <h4>Cameron Moffat</h4>
                                        
                                    </div>

                                </figure>
                                <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://www.facebook.com/YaBoyCamachan" 
                                            quote= "Cameron Moffat" hashtag="#cameronmoffat">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url="https://uk.linkedin.com/in/cameron-moffat-501792225" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div>*/}
                    </div>
                </div>
            </div>          
        </section>
        </>
    )
}

export default Team
