import React, { Component } from "react";
import Slider from "react-slick";
import bg_vide from '../../videos/test.mp4'
import {Link} from 'react-scroll'
import { BrowserView, MobileView } from 'react-device-detect';

export default class SimpleSlider3 extends Component {
  render() {
    const settings = {
      dots: false,
      arrows:false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000
    };
    return (
      <>
        
        <Slider {...settings}>
            <div  className="full-screen-block relative">
                <BrowserView>
                  <video autoPlay loop muted className="bg_video">
                      <source src={bg_vide} type="video/mp4"></source>
                  </video>
                </BrowserView>
                <MobileView>
                  <img src="/img/bg/edi.jpg" className="bg_video"/>
                </MobileView>
                <div className="bg-dark"></div>
                <div className="sliderTitleBlock" onClick={()=>{
                                alert("IMPORTANT! Please ensure to enter your mobile phone number in the right format (such as +447490001234) in the checkout page to get instant access to the WhatsApp Group.");
                                window.open('https://buy.stripe.com/aEUcQyaYw0FOdUc6or', '_blank');
                            }}>
                    
                    <h2 className= "homeSliderTitle">JOIN The UK #1 Property Sourcing Training Platform</h2>
                    <Link to="#" onClick={()=>{
                                alert("IMPORTANT! Please ensure to enter your mobile phone number in the right format (such as +447490001234) in the checkout page to get instant access to the WhatsApp Group.");
                                window.open('https://buy.stripe.com/aEUcQyaYw0FOdUc6or', '_blank');
                            }} className="btn-default active btn-move t1" smooth={false} duration={1200} style={{color: 'white', backgroundColor: '#b46e3c', zIndex: 999999}}>JOIN NOW FOR £69</Link>
                </div>
            </div>
        </Slider>
      </>
    );
  }
}