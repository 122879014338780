import React from 'react'
import {Link} from 'react-scroll'

const services = [
    {
        _id:'001',
        classIcon:'ion-arrow-graph-up-right',
        title:'Opportunities',
        text:'Opportunity to sell deals to 15,000+ investors with weekly deal opportunities within the group.'
    },
    {
        _id:'002',
        classIcon:'ion-university',
        title:'Learn from The Best',
        text:'Work closely and learn from two of the most active sourcers in the country.'
    },
    {
        _id:'003',
        classIcon:'ion-network',
        title:'Networking',
        text:'Network of extremely active sourcers with regular site visits and social meetups.'
    },
    {
        _id:'004',
        classIcon:'ion-ios-telephone-outline',
        title:'Call Support',
        text:'Bi weekly recorded zoom calls and immediate access to David & Matt.'
    },
    {
        _id:'005',
        classIcon:'ion-android-lock',
        title:'Private Members Area',
        text:'Unlimited access to a bespoke members area with plenty templates and other useful content.'
    },
    {
        _id:'006',
        classIcon:'ion-calendar',
        title:'Regular Guest Calls',
        text:'A busy calendar with plenty professional guests with PSN-exclusive content.'
    }
]
function Service3() {
    return (
        <>
        <section  id="services">
        <div className="bg-dark"></div> 
            <div className="section-block-title">
                <div className="section-title">   
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-sm-10">
                                <article className="section-title-body">
                                    <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">What is PSN</h1>
                                    <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                    <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s" style={{textAlign: 'justify'}}> 
                                    Property sourcing network is the one-time solution for new and existing property sourcers and investors to find ultimate success in property. <br/><br/>Whether it's growing your business for the first time, or scaling their existing portfolio with the assistance of a high-performing network containing hand-held training, exclusive knowledge and sought-after market strategies.
                                    </p>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> 
                </div>
                <div className="block color-scheme-blur">
                    <div className="container">
                        <div className="row">
                            {services.map((service)=>(
                            <div className="col-lg-4 col-md-6"  key={service._id}>
                                <div className="block-feature">
                                    <i className={service.classIcon}></i>
                                    <a href="/">{service.title}</a>
                                    <p>
                                    {service.text}
                                    </p>
                                </div>
                            </div>
                            ))}
                        </div>
                        <center><Link to="#" onClick={()=>{
                                alert("IMPORTANT! Please ensure to enter your mobile phone number in the right format (such as +447490001234) in the checkout page to get instant access to the WhatsApp Group.");
                                window.open('https://buy.stripe.com/aEUcQyaYw0FOdUc6or', '_blank');
                            }} className="btn-default active btn-move t1" smooth={false} duration={1200} style={{color: 'white', backgroundColor: '#b46e3c'}}>JOIN NOW FOR £69</Link></center>

                    </div>
                </div>
            
        </section>
        </>
    )
}

export default Service3
