import React from 'react'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Vimeo from '@u-wave/react-vimeo';

const portfolioAll = [
    {
        _id:'001',
        src:'910522186',
        title:'Adam Brown',
        category:'AWB Property Sourcing'
    },
    {
        _id:'002',
        src:'910522268',
        title:'Greg White',
        category:'TG Property'
    },
    {
        _id:'003',
        src:'910522226',
        title:'David Liddel',
        category:'Liddel Property Investments'
    },
    {
        _id:'004',
        src:'910522310',
        title:'Mark Reilly',
        category:'NL Property Group'
    },
    {
        _id:'005',
        src:'910522365',
        title:'Darren Sharp',
        category:'Sharp Property Investments'
    },
    {
        _id:'006',
        src:'910522426',
        title:'Connagh Fletcher',
        category:'Fletcher Property Solutions'
    }
]

function Portfolio3() {
    return (
        <>
        <section  id="portfolio">
        <div className="bg-dark"></div>
            <div className="section-block-title">
                <div className="section-title">   
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-sm-10">
                                <article className="section-title-body">
                                    <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Testimonials</h1>
                                    <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                    <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                        Hear what our members have to say
                                    </p>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className="block color-scheme-blur">
                    <div className="container">
                        
                            <SimpleReactLightbox>
                                <SRLWrapper>
                                <div className="row">
                                    {portfolioAll.map((portfolio)=>(
                                        <div className="col-lg-4 col-md-6"  key={portfolio._id}>
                                            <div className="portfolio-block">
                                                <a href={portfolio.src}>
                                                    <div alt={portfolio.title} className="img-fluid" src={portfolio.src}>
                                                        <Vimeo video={portfolio.src} style={{width: '100%', backgroundColor: '#000', textAlign: 'center'}}/>
                                                    </div>
                                                </a>
                                                <div className="portfolio-content">
                                                    <p>{portfolio.category}</p>
                                                    <h4>{portfolio.title}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                </SRLWrapper>
                            </SimpleReactLightbox> 
                        
                    </div> 
                    <div className="clearfix"></div>
                </div>
            
            
        </section>
   
        </>
    )
}

export default Portfolio3
