import React from 'react';
import {FacebookShareButton,
        PinterestShareButton,
        TelegramShareButton,
        LinkedinShareButton,
        InstapaperShareButton,
        TwitterShareButton} from "react-share";
import {FacebookIcon,
        PinterestIcon,
        TelegramIcon,
        LinkedinIcon,
        TwitterIcon} from "react-share";
import {Link} from 'react-scroll'

function Footer3() {
    return (
        <>
        <footer id="footer">
            <div className="container">
                {/*<div className="row">
                    <div className="col-md-12">
                        <article className="footer-info-block">
                            <ul className="list-unstyled flickr">
                                <li><a href="/"><img src="img/preview/blog1.jpg" className="img-fluid" alt=""/></a></li>
                                <li><a href="/"><img src="img/preview/blog2.jpg" className="img-fluid" alt=""/></a></li>
                                <li><a href="/"><img src="img/preview/blog5.jpg" className="img-fluid" alt=""/></a></li>
                                <li><a href="/"><img src="img/preview/blog3.jpg" className="img-fluid" alt=""/></a></li>
                                <li><a href="/"><img src="img/preview/blog4.jpg" className="img-fluid" alt=""/></a></li>
                                <li><a href="/"><img src="img/preview/blog6.jpg" className="img-fluid" alt=""/></a></li>
                                <li><a href="/"><img src="img/preview/blog8.jpg" className="img-fluid" alt=""/></a></li>
                            </ul>
                        </article>
                    </div>
                </div>*/}
                
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-8 order-1 col-xs-12 col-sm-8">
                            <ul className="footer-icon list-unstyled">
                                <li><Link to="#" onClick={()=>window.open('https://sourcing-network-scotland.web.app/session/signin', '_blank')} className="btn-default active btn-move t1" smooth={false} duration={800}>LOGIN TO MEMBERS AREA</Link></li>
                                <li>
                                    <FacebookShareButton url="https://www.facebook.com/propertysourcingnetwork" 
                                    quote= "PSN" hashtag="#psn">
                                        <FacebookIcon round={true} size={32}></FacebookIcon>
                                    </FacebookShareButton>
                                    
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="footer-logo">
                                <img src="img/logo-big-shop.png" alt=""/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </footer>
        </>
    )
}

export default Footer3
